import { NextPage } from 'next';
import Image from 'next/legacy/image';
import { Title, Text, Container, useMantineTheme, Button } from '@mantine/core';

const Custom404: NextPage = () => {
  const theme = useMantineTheme();
  return (
    <div
      style={{
        background: `${theme.colors.blue[8]} url('/backgroundPattern.png') no-repeat`,
        backgroundSize: 'cover',
        color: 'white',
        textAlign: 'center',
        height: '100vh',
      }}
    >
      <Container p="xl" mt="xl" fluid>
        <Image src="/blocks.png" alt="Tower of puzzle blocks" width={180} height={140} />
        <Title pt="md" pb="xs" order={3}>
          Sorry, something went wrong.
        </Title>
        <Text>We’re working on getting this fixed as soon as we can. </Text>
        <Button
          component="a"
          href="mailto:support@bookpebble.co.uk?subject=Page%20loading%20error"
          style={{
            width: 'fit-content',
            margin: '36px 0',
            backgroundColor: theme.colors.pink[5],
            color: 'white',
            '&:hover': {
              backgroundColor: theme.other.pinkHover,
            },
          }}
          radius="lg"
          size="md"
        >
          Contact us
        </Button>
      </Container>
    </div>
  );
};

export default Custom404;
